<script>
import VueSelect from 'vue-select';
import IconChevronDown from '~~/assets/svg/icons/chevron-down.svg';

export default {
  extends: VueSelect,

  props: {
    components: {
      type: Object,
      default: () => ({
        // Deselect: {
        //   render: () => h(XIcon, { attrs: { width: '1em', height: '1em' } }),
        // },
        OpenIndicator: IconChevronDown,
      }),
    },
  },
};
</script>

<style>
@import 'vue-select/dist/vue-select.css';
</style>

<style lang="postcss">
:root {
  /* Font */
  --vs-font-size: 14px;
  --vs-line-height: 24px;

  /* Disabled State */
  --vs-state-disabled-bg: #e9ecef;
  --vs-state-disabled-cursor: auto;

  /* Borders */
  --vs-border-color: theme(colors.primary.DEFAULT);
  --vs-border-radius: 0;

  /* Component Controls: Clear, Open Indicator */
  --vs-controls-color: theme(colors.primary.DEFAULT);

  /* Selected */
  --vs-selected-bg: #dee2e6;
  --vs-selected-color: inherit;

  /* Dropdown */
  --vs-dropdown-bg: theme(colors.input.bg);
  --vs-dropdown-box-shadow: 0 0.75rem 1rem rgb(0 0 0 / 15%);

  /* Active State */
  --vs-dropdown-option--active-bg: theme('colors.primary.DEFAULT');
}

.v-select {
  --input-py: theme('spacing.input-py');
}

.form-control-lg {
  --vs-font-size: theme('fontSize.input-lg');
  --vs-line-height: theme('lineHeight.input-lg');
  --input-py: theme('spacing.input-lg-py');
}

.v-select {
  background: theme('colors.input.bg');
}

.vs__dropdown-toggle,
.vs__dropdown-menu {
  .vs--open & {
    border-color: #86b7fe;
  }
}

.vs__dropdown-toggle {
  padding-bottom: var(--input-py);
}

.vs__selected-options {
  padding-left: theme('spacing.input-px');
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  .vs--single & {
    flex-wrap: nowrap;
  }
}

.vs__selected,
.vs__search,
.vs__search:focus,
.vs__actions {
  margin-top: var(--input-py);
}

.vs__selected,
.vs__search,
.vs__search:focus {
  border: 0;
}

.vs__search,
.vs__search:focus {
  padding-left: 0;
}

.vs__search::placeholder {
  opacity: 0.75;
}

.vs__selected {
  margin-left: 0;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  margin-right: 1.25rem;

  .vs--single & {
    padding-left: 0;
    padding-right: 0;
    margin-right: 0;
  }
}

.vs__actions {
  padding-top: 0;
  padding-right: 12px;

  svg {
    width: var(--vs-line-height);
    height: var(--vs-line-height);
  }
}

.vs__clear {
  line-height: 1;
  color: #6c757d;
  margin-top: -0.05em;
}

.vs__dropdown-option {
  overflow: hidden;
  text-overflow: ellipsis;
}

.vs__no-options {
  color: #6c757d;
  padding-left: theme('spacing.input-px');
  padding-right: theme('spacing.input-px');
}
</style>
